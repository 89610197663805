import React from 'react';

const IconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.331 21.331"
    style={{
      enableBackground: 'new 0 0 21.331 21.331',
    }}
    xmlSpace="preserve">
    <path
      fill="currentColor"
      d="M10.19 11.288c.75-.038 2.656-.197 2.656-.197V7.724l-2.856 3.21c0 .001-.555.391.2.354zm8.541-1.345s-.75-.594-.712-2.734c.039-2.141.081-3.963.081-3.963s-.318-5.944-5.985-1.744L2.843 12.321s-3.289 5.15 3.568 6.417c1.743-.118 3.446-1.188 4.595.753.436.753 2.021 3.014 5.547 1.072.517-.398 1.229-1.19 1.267-3.369.081-.832-.081-4.984.912-5.048-.001 0 1.228-1.451-.001-2.203zm-2.32 1.546c-.854.109-1.179.217-1.268.198 0 .527.039 4.922.039 6.299-1.229 1.349-2.496 0-2.496 0s-.077-3.369-.038-5.759c-4.56.529-6.34 2.946-6.739 3.303-.475.197-1.03-.239-1.03-.239-.555-.157 0-.99 0-.99l8.362-9.906c1.585-1.149 1.783.514 1.783.514v6.181s.633 0 1.345-.038c.716.236.042.437.042.437z"
    />
  </svg>
);

export default IconLogo;
